import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonUnderline";

// import ellipse from "../images/10 About us/Ellipse.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="About Us | Home Loans | Accel Mortgage Brokers"
        description="As your Pasadena mortgage broker, we believe in providing you with a personalized, superior lending experience tailored to your needs and goals. Read more."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>What We’re About</h1>
              <p>
                As a top Pasadena mortgage broker serving LA County and all of
                California, we believe in providing you with a superior lending
                experience tailored to your needs and goals. Our approach is
                personalized, and we value the long-lasting relationships we
                build with our clients.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/10 About us/1.0 Hero.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          {/* <img src={ellipse} className="absolute left-0 top-0" /> */}
          <div className="rounded-6xl bg-white py-14 px-6 text-center shadow-5xl">
            <header className="mx-auto max-w-[772px]">
              <h2>Our Mission</h2>
              <p className="mb-0 tracking-wide md:text-lg md:leading-[32px]">
                At Accel Mortgage, our mission is simple—to provide top-notch
                customer service and bring you the best that the marketplace
                offers. We’re in the people business, and helping you is our top
                priority.
              </p>
            </header>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/10 About us/2.0 Philosophy.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
            <div>
              <h2>Our Philosophy</h2>
              <p className="mb-0">
                We value the individual—analyzing loan scenarios and basing our
                advice on your personalized lending needs. And we don't just
                focus on finding a great fit for you today. We also aim to
                create long-term financial health for you and your family.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ValueProps />

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-10 md:grid-cols-2 md:gap-x-5 lg:gap-x-10">
            <div className="md:pr-10">
              <h2>The Accel Team</h2>
              <p className="mb-0">
                Whether buying, selling, refinancing, or building your dream
                home, you have a lot riding on your loan officer. Ensuring that
                you make the right choice for you and your family is important,
                and we’re committed to providing you with mortgage services that
                exceed your expectations.
              </p>
            </div>
            <div>
              <img src="" className="z-0 mb-6 rounded-6xl shadow-5xl" />
              <StaticImage
                src="../images/10 About us/FrankMoreno.jpg"
                loading="lazy"
                className="z-0 mb-6 rounded-6xl shadow-5xl"
              />
              <h3 className="heading-six mb-1">Frank Moreno</h3>
              <div className="mb-3 font-display text-sm font-light uppercase tracking-wide">
                COO / President
              </div>
              <p className="mb-0">
                Frank is a long-time real estate professional who’s an
                entrepreneur at heart—he’s founded and successfully managed
                several companies during his career. He also has a passion for
                technology and consistently incorporates innovative processes to
                create a seamless experience for you.
              </p>
            </div>

            {/* <div>
              <StaticImage
                src="../images/10 About us/mark white background.png"
                loading="lazy"
                className="z-0 mb-6 rounded-6xl shadow-5xl"
              />
              <h3 className="heading-six mb-1">Mark Darling</h3>
              <div className="mb-3 font-display text-sm font-light uppercase tracking-wide">
                CEO / Broker
              </div>
              <p className="mb-0">
                Mark has an extensive background in lending and real estate,
                with an emphasis on management, sales, and marketing. He wants
                every client to have a great loan experience when working with
                Accel Mortgage. He also fosters relationships between lenders
                and realtors to help you get the best out of the marketplace.
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/10 About us/4.0 Careers.png"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
            <div>
              <h2>We Are Hiring</h2>
              <p>
                If you're a mortgage professional who wants to grow your career
                and sees the value in developing lifelong relationships with
                clients, we want to hear from you.
              </p>
              <ButtonUnderline href="#" text="Learn More" />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
